import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import { RiTwitterXLine } from "react-icons/ri";

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/mohitrachuri/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/mohitrachuri" target="_blank"><BsGithub/></a>
        <a href="https://x.com/MohitRachuri" target="_blank"><RiTwitterXLine/></a>
    </div>
  )
}

export default HeaderSocials